<template>
  <div class="e-header">
    <offerBatchOpera @valChange="valChange" :isSimpleOffer="!isSimpleOffer" />
    <el-button type="success" size="mini" @click="clickExportOffer">导出报价单</el-button>
    <el-button type="success" size="mini" @click="exportInquiry">导出询价单</el-button>
    <template v-if="isEdit">
      <el-button style="margin-left: 20px;" type="primary" size="mini" @click="confirm('offerGoods')">确认报价</el-button>
      <el-button style="margin-left: 20px;" type="warning" size="mini" @click="addGoods">添加商品</el-button>
    </template>
    <template v-else>
      <el-button style="margin-left: 20px;" type="warning" size="mini" @click="confirm('createOrder')">转销售单</el-button>
    </template>
    <el-button style="margin-left: 20px;" type="warning" size="mini" @click="turnInquiry">再次发起询价</el-button>
  </div>
  <div style="float:right;margin:0 2%">
    <span>共{{ data.length }}件商品，共{{ dataTotal }}件</span>
    <span style="fontSize:20px;color: #D84146;">总金额（不含运费）:</span>
    <span>￥{{ num.toFixed(2) }}</span>
  </div>
  <lida-table :pagination="false" :cutHeight="0" :data="data" border ref="table">
    <!-- :max-height="1000" -->
    <lida-table-column label="产品信息">
      <template #default="scope">
        <div class="g-info">
          <div class="g-info-right">
            <div class="g-info-row g-info-title">
              {{ scope.row.productName }} | {{ scope.row.seriesName }}
            </div>
            <div class="g-info-row">
              <span>参数:</span>
              <p>{{ scope.row.barCode }}</p>
            </div>
            <div class="g-info-row">
              <span>订货号:</span>
              <p>{{ scope.row.articleNo }}</p>
            </div>
            <div class="g-info-row">
              <el-tag>{{ scope.row.brandName }}</el-tag>
            </div>
          </div>
        </div>
      </template>
    </lida-table-column>
    <el-table-column label="面价" prop="guidePrice" :min-width="150" align="center">
      <template #default="scope">
        <el-input v-model="scope.row.guidePrice" @input="inputLv(scope.row)" placeholder="请输入面价" :disabled="!isEdit" />
      </template>
    </el-table-column>

    <lida-table-column label="采购折扣(%)" :min-width="150" align="center" v-if="!isSimpleOffer">
      <template #default="scope">
        <div class="t-row">
          <el-input-number v-model="scope.row.purchaseDis" size="small" :disabled="!isEdit"
            @change="inputLv(scope.row, 'purchaseDis')" />
        </div>
      </template>
    </lida-table-column>

    <lida-table-column label="采购单价" :min-width="150" align="center" v-if="!isSimpleOffer">
      <template #default="scope">
        <el-input-number v-model="scope.row.purchasePrice" size="small" :disabled="!isEdit"
          @change="inputLv(scope.row, 'purchasePrice')" />
      </template>
    </lida-table-column>

    <lida-table-column label="加点折扣" :min-width="150" align="center" class="redColor" v-if="!isSimpleOffer">
      <template #header="{ column }">
        <span style="color:red">{{ column.label }}</span>
      </template>
      <template #default="scope">
        <div class="t-row">
          <el-input-number v-model="scope.row.guideSite" size="small" :disabled="!isEdit"
            @change="inputLv(scope.row, 'guideSite')" />
        </div>
      </template>
    </lida-table-column>

    <lida-table-column label="利润点数(%)" :min-width="150" align="center" v-if="!isSimpleOffer">
      <template #header="{ column }">
        <span style="color:red">{{ column.label }}</span>
      </template>
      <template #default="scope">
        <div class="t-row">
          <el-input-number v-model="scope.row.profitSite" size="small" :disabled="!isEdit"
            @change="inputLv(scope.row, 'profitSite')" />
        </div>
      </template>
    </lida-table-column>

    <lida-table-column label="销售折扣(%)" :min-width="150" align="center">
      <template #default="scope">
        <div class="t-row">
          <el-input-number v-model="scope.row.salesDis" size="small" :disabled="!isEdit"
            @change="inputLv(scope.row, 'salesDis')" />
        </div>
      </template>
    </lida-table-column>

    <lida-table-column label="销售价格" :min-width="150" align="center">
      <template #default="scope">
        <div class="t-row">
          <el-input-number v-model="scope.row.salesPrice" size="small" :disabled="!isEdit"
            @change="inputLv(scope.row, 'salesPrice')" />
        </div>
      </template>
    </lida-table-column>

    <lida-table-column label="报价数量" prop="qty" :min-width="140" align="center" />

    <lida-table-column label="生产日期" :min-width="120" align="center">
      <template #default="scope">
        <el-date-picker v-model="scope.row.manufactureDate" type="date" placeholder="请选择" :disabled="!isEdit"
          size="small" :disabled-date="disabledDate">
        </el-date-picker>
      </template>
    </lida-table-column>

    <lida-table-column label="渠道" :min-width="120" align="center">
      <template #default="scope">
        <el-select v-model="scope.row.channel" placeholder="请选择" size="small" :disabled="!isEdit">
          <el-option v-for="item in channel" :key="item.title" :label="item.title" :value="item.title">
          </el-option>
        </el-select>
      </template>
    </lida-table-column>

    <lida-table-column label="地区" :min-width="120" align="center">
      <template #default="scope">
        <el-cascader v-model="scope.row.shipArea" :options="areaInfo" size="small" :props="{ expandTrigger: 'hover' }"
          style="width: 100%;" :disabled="!isEdit"></el-cascader>
      </template>
    </lida-table-column>

    <lida-table-column label="实际利润" fixed="right" :min-width="140" align="center" v-if="!isSimpleOffer">
      <template #default="scope">
        <div class="t-profit">
          <div class="t-profit-i">
            <span>实际利润:</span>
            <span>{{ scope.row.profitC }}</span>
          </div>
        </div>
      </template>
    </lida-table-column>

    <lida-table-column label="总利润" fixed="right" :min-width="140" align="center" v-if="!isSimpleOffer">
      <template #default="scope">
        <div class="t-profit">
          <div class="t-profit-i">
            <span>总利润:</span>
            <span>{{ scope.row.totalProfitC }}</span>
          </div>
        </div>
      </template>
    </lida-table-column>

    <!-- <lida-table-column label="实际利润" fixed="right" :min-width="140" align="center">
			<template #default="scope">
				<div class="t-profit">
					<div class="t-profit-i">
						<span>实际利润:</span>
						<span>{{scope.row.profitC}}</span>
					</div>
				</div>
			</template>
		</lida-table-column>

		<lida-table-column label="总利润" fixed="right" :min-width="140" align="center">
			<template #default="scope">
				<div class="t-profit">
					<div class="t-profit-i">
						<span>总利润:</span>
						<span>{{scope.row.totalProfitC}}</span>
					</div>
				</div>
			</template>
		</lida-table-column> -->

    <lida-table-column label="操作" fixed="right">
      <template #default="scope">
        <el-button size="mini" @click="deleteRow(scope)" type="text">移除</el-button>
      </template>
    </lida-table-column>
  </lida-table>
  <!-- <div style="float:right;margin:0 2%">
    <span style="fontSize:20px;color: #D84146;">总金额:</span>
    <span>￥{{ num }}</span>
  </div> -->
  <add-goods-dialog ref="addRef" v-model="show" :ids="ids" @success="addSuccess" />
  <offer-info-dialog ref="offerInfo" v-model="offerInfoVisible" :data="data" :quoteInfos="quoteInfos"
    @success="exportSuccess" />

  <div class="switch" @click="isSimpleOffer = !isSimpleOffer">
    {{ isSimpleOffer ? "复杂版" : "简单版" }}
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";
import { throttle, handleTreeData } from "@/common/js/common.js";
import offerProfitCompute from "@/common/js/offerProfitCompute.js";
import area from "@/common/json/area.json";
import moment from "moment";
import offerInfoDialog from "../dialog/offerInfo.vue"; // 导出报价单弹窗
import addGoodsDialog from "../dialog/addGoods.vue"; //添加商品弹框
import exportExcel from "@/common/js/exportExcel.js"; //导出数据
import offerBatchOpera from "@/components/publicComponent/offerBatchOpera.vue"; //批量操作
import { log } from 'util';
// import { inject } from 'vue'
export default {
  data() {
    return {
      data: [],
      show: false, //添加弹框的隐藏显示
      ids: [],
      areaInfo: handleTreeData(area, "name", "name"), //地区信息
      isSimpleOffer: false,
      offerInfoVisible: false,
      num: 0,
    };
  },
  props: ["info", "quoteInfos"],
  computed: {
    ...mapState("mysaleMudule", ["channel"]),
    dataTotal() {
      return this.data.reduce((pre, cur) => {
        return pre + cur.qty * 1
      }, 0)
    },
    isEdit() {
      return (
        (this.info.inquiryRequestVO.documentState === "WAIT_QUOTE" ||
          this.info.inquiryRequestVO.documentState === "COMPLETE_QUOTE") &&
        (!this.info.sellerQuoteList || !this.info.sellerQuoteList.length)
      );
    },
  },
  components: {
    offerInfoDialog,
    addGoodsDialog,
    offerBatchOpera,
  },
  created() {
    //事件节流
    this.confirm = throttle(this.confirm);
    var data =
      this.info.sellerQuoteList && this.info.sellerQuoteList.length
        ? this.info.sellerQuoteList
        : this.info.itemRequestVOList;

    data = JSON.parse(JSON.stringify(data || []));
    console.log(this.info,'------')
    data.map((item) => {
      item.qty = item.qty || 1; //数量
      item.manufactureDate =
        item.manufactureDate || moment().format("YYYY-MM-DD"); //生产日期
      item.channel = item.channel || "现货"; //渠道
      item.purchasePrice = item.purchasePrice || ""; //采购单价
      item.profitSite = item.profitSite || ""; //利润点数
      item.guideSite = item.guideSite || ""; //面价点数
      item.guidePrice = (item.guidePrice || item.skuGuidePrice || 0).toFixed(2);
      item.shipArea =
        typeof item.shipArea === "string"
          ? item.shipArea.split(",")
          : item.shipArea || [];
      item.numType = "qty";
      return offerProfitCompute(item);
    });
    this.data = data;
    this.data.forEach((v) => {
      this.num += v.qty * v.salesPrice;
      // console.log(this.num);
    });
  },
  methods: {
    ...mapActions("purchaseMudule", [
      "quoteCreate", //新增报价
      "quoteModifyQuoteItem", //修改报价
      "purchaseCartCreatePurchaseByQuote", //转销售单
    ]),
    ...mapMutations("purchaseMudule", ["renewTurnInquiryInfo"]),
    ...mapMutations("mysaleMudule", ["setOfferSuccessType"]),
    confirm(type) {
      //确认报价的点击事件
      var data = JSON.parse(JSON.stringify(this.data));
      var a = data.filter(
        (v) =>
          v.manufactureDate &&
          v.channel &&
          // v.purchasePrice &&
          v.salesDis &&
          v.salesPrice &&
          v.shipArea
      );
      console.log(a.length);
      if (a.length) {
        for (var i = 0; i < a.length; i++) {
          a[i].shipArea = a[i].shipArea.join(",");
          a[i].manufactureDate = moment(a[i].manufactureDate).format(
            "YYYY-MM-DD"
          );
          a[i].skuId = a[i].skuId || a[i].id;
          if (this.info.sellerQuoteList && this.info.sellerQuoteList.length) {
            a[i].id = a[i].itemId;
          }
        }
        if (a.length === data.length) {
          if (type) {
            this[type](a);
          } else {
            this.offerGoods(a);
          }
        } else {
          ElMessageBox.confirm(
            "没填完报价信息的商品会自动过滤，是否继续报价?",
            "提示",
            {
              confirmButtonText: "确认",
              cancelButtonText: "取消",
              type: "warning",
              center: true,
            }
          )
            .then(() => {
              if (type) {
                this[type](a);
              } else {
                this.offerGoods(a);
              }
            })
            .catch(() => { });
        }
      } else {
        for (var i = 0; i < data.length; i++) {
          if (!data[i].manufactureDate) {
            return ElMessage({
              type: "warning",
              message: "请选择生产日期",
            });
          } else if (!data[i].channel) {
            return ElMessage({
              type: "warning",
              message: "请选择渠道",
            });
          } else if (!data[i].shipArea || !data[i].shipArea.length) {
            return ElMessage({
              type: "warning",
              message: "请选择地区",
            });
          }
          // else if (!data[i].purchasePrice) {
          //   return ElMessage({
          //     type: "warning",
          //     message: "请填写采购单价",
          //   });
          // }
          else if (!data[i].salesDis) {
            return ElMessage({
              type: "warning",
              message: "请填写销售则扣",
            });
          } else if (!data[i].salesPrice) {
            return ElMessage({
              type: "warning",
              message: "请填写销售价格",
            });
          }
        }
      }
    },
    offerGoods(a) {
      //报价
      if (this.info.sellerQuoteList && this.info.sellerQuoteList.length) {
        //如果有报价信息则修改报价
        this.quoteModifyQuoteItem({
          itemRequestVOList: a,
          inquiryId: this.info.inquiryRequestVO.id,
        }).then((res) => {
          let { data, code } = res.data;
          if (code === "0") {
            ElMessage({
              type: "success",
              message: "修改报价成功",
            });
            this.$emit("bjSuccess");
            //跳转到我的报价页面
            this.setOfferSuccessType(true);
            this.$router.push({
              path: "/mysale/customerOffer",
            });
          }
        });
      } else {
        //新增报价
        this.quoteCreate({
          itemRequestVOList: a,
          quoteRequestVO: {
            inquiryId: this.info.inquiryRequestVO.id,
            buyerEnterpriseId: this.info.inquiryRequestVO.buyerEnterpriseId,
          },
        }).then((res) => {
          let { data, code } = res.data;
          if (code === "0") {
            ElMessage({
              type: "success",
              message: "报价成功",
            });
            this.$emit("bjSuccess");
            //跳转到我的报价页面
            this.setOfferSuccessType(true);
            this.$router.push({
              path: "/mysale/customerOffer",
            });
          }
        });
      }
    },
    getOneKey(data) {
      for (var key in data) return data[key];
    },
    deleteRow(obj) {
      //删除商品的事件
      this.data.splice(obj.$index, 1);
    },
    addGoods() {
      //添加商品
      this.$refs.addRef.clearData();
      this.show = true;
    },
    addSuccess(data) {
      //添加成功的回调
      var arr = JSON.parse(JSON.stringify(this.data));
      data.map((item) => {
        if (!arr.some((v) => v.id === item.id)) {
          arr.push(item);
        }
      });
      this.data = [...arr];
    },
    exportSuccess() {
      //
    },
    inputLv(row, type, linkage) {
      //监听面价点数和利润点数输入的事件
      row = offerProfitCompute(row, type, linkage);
    },
    clickExportOffer() {
      if (this.quoteInfos) {
        this.offerInfoVisible = true;
      } else {
        ElMessage({
          type: "warning",
          message: "暂无报价单数据",
        });
      }
    },
    exportInquiry() {
      //导出询价单
      var arr = [];
      var data = JSON.parse(JSON.stringify(this.data));
      data.map((item) => {
        arr.push({
          产品信息:
            item.productName +
            "|" +
            item.brandName +
            "1" +
            item.barCode +
            "|" +
            item.seriesName +
            "|" +
            item.skuNumber,
          订货号: item.articleNo,
          面价: item.guidePrice,
          生产日期: item.manufactureDate,
          渠道: item.channel,
          地区: item.shipArea.join(","),
          采购单价: item.purchasePrice,
          利润点数: item.profitSite,
          面价点数: item.guideSite,
          数量: item.qty,
        });
      });
      exportExcel(arr, "询价单");
    },
    disabledDate(time) {
      return time.getTime() > Date.now();
    },
    turnInquiry() {
      //转成我的询价
      var info = JSON.parse(JSON.stringify(this.info));
      info.itemRequestVOList = JSON.parse(JSON.stringify(this.data));
      this.renewTurnInquiryInfo(info);
      this.$router.push({
        path: "/purchase/addInquiryPage",
      });
    },
    valChange(val, key, isCompute) {
      this.data.map((item) => {
        item[key] = val;
        if (isCompute) {
          item = this.inputLv(item, key);
        }
        return item;
      });
    },
    //转销售单
    createOrder(a) {
      if (a.length) {
        a.map((item) => {
          item.purchaseQty = item.qty;
        });
        this.purchaseCartCreatePurchaseByQuote({
          purchaseDocumentItemVOList: a,
          inquiryId: this.info.inquiryRequestVO.id,
          buyerEnterpriseId: this.info.inquiryRequestVO.buyerEnterpriseId,
          quoteId: this.$route.query.id,
          buyerId: this.info.inquiryRequestVO.buyerId,
        }).then((res) => {
          let { code, data } = res.data;
          if (code === "0") {
            ElMessage({
              type: "success",
              message: "生成采购单成功",
            });
            
            this.$emit("success");
            this.$router.push(`/mysale/xinOrderDetails?id=${data}&inquiryId=${this.info.inquiryRequestVO.id}`);
          }
        });
      } else {
        ElMessage({
          type: "warning",
          message: "请选择要生成采购单的商品",
        });
      }
    },
  },
  watch: {
    data(v) {
      var ids = [];
      v.map((item) => ids.push(item.skuId || item.id));
      this.ids = ids;
    },
  },
};
</script>

<style scoped lang="less">
@import "@/module/mysaleMudule/style/editOfferInfo.less";

.switch {
  width: 60px;
  height: 30px;
  border-radius: 4px;
  background: #d48a1b;
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  position: fixed;
  top: 80px;
  right: 80px;
}

.switch::after {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #d48a1b;
  border-top: 15px solid transparent;
  right: -13px;
}

// /deep/ .el-table .el-table--scrollable-x .el-table__body-wrapper .el-table--border {
// 	height: 400px!important;
// }</style>
