<template>
	<el-dialog width="1300px" top="6vh" :destroy-on-close="true">
		<div class="a">
			<div class="a-title">添加商品</div>
			<div class="p">
				<div class="p-top">
					<span>商品搜索</span>
					<el-input v-model="value" :rows="7" type="textarea" placeholder="例如：
						A9F29413  1
						iC65N 2P C16A  2
						LC1DT32M7C  1
						6ES7511-1CK01-0AB0  6
						6ES7521-1BH50-0AA0  3
						6ES7540-1AB00-0AA0  3" style="margin-right: 40px;" />
					<div style="margin-left: 20px;display: flex;">
						<el-button size="mini" @click="search" type="primary">查询</el-button>
						<div style="color: #999999;margin-left: 20px;font-size: 12px;">
							批量添加订货号（型号）和数量，用空格隔开，也可从Excel中复制粘贴，然后按查询。</div>
					</div>
				</div>
				<!-- 商品信息 -->
				<lida-table :pagination="false" :cutHeight="150" :data="data" border @selection-change="selectGoods"
					ref="table">
					<el-table-column :selectable="checkSelectable" type="selection" width="55" fixed />
					<lida-table-column label="产品信息" fixed>
						<template #default="scope">
							<div class="g-info">
								<div class="g-icon">
									<img :src="scope.row.src" />
								</div>
								<div class="g-right">
									<div class="g-r-title">
										{{ scope.row.productName }}
										<div class="label" :title="scope.row.brandName">{{ scope.row.brandName }}</div>
									</div>
									<div class="g-r-data">
										{{ scope.row.barCode || '无' }}
										<span>|</span>
										{{ scope.row.seriesName || '无' }}
										<span>|</span>
										{{ scope.row.skuNumber || '无' }}
									</div>
								</div>
							</div>
						</template>
					</lida-table-column>
					<lida-table-column label="订货号" prop="articleNo" />
					<lida-table-column label="面价" prop="guidePrice" />
					<lida-table-column label="操作" fixed="right">
						<template #default="scope">
							<el-button size="mini" @click="deleteRow(scope)" type="text">移除</el-button>
						</template>
					</lida-table-column>
				</lida-table>
			</div>
			<div class="a-footer">
				<el-button style="margin-right: 20px;" size="mini" @click="close">取消</el-button>
				<el-button style="margin-left: 20px;" type="primary" size="mini" @click="confirm">确认</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ElMessage } from 'element-plus'
import offerProfitCompute from '@/common/js/offerProfitCompute.js'
import moment from "moment"
export default {
	data() {
		return {
			value: '',  //搜索值
			data: [],  //列表数据
		}
	},
	props: {
		ids: { //需要置灰的商品ids
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	methods: {
		...mapActions('purchaseMudule', [
			'skuPage'
		]),
		search() { //搜索的点击事件
			if (this.value) {
				this.skuPage({
					wdBatch: this.value,
					pageNo: 1,
					pageSize: 30,
				}).then(res => {
					let { code, data } = res.data;
					if (code === "0") { //请求成功
						//每次搜索结果数据累加
						data.rows.map(item => {
							item.qty = 1; //数量
							item.manufactureDate = ''; //生产日期
							item.channel = '';  //渠道
							item.purchasePrice = '';  //采购单价
							item.profitSite = '';  //利润点数
							item.guideSite = '';  //面价点数
							item.guidePrice = item.guidePrice.toFixed(2);
							item.disabled = this.ids.some(v => v === item.id);  //是否禁用
							item.purchaseDis = '';  //采购折扣
							item.salesDis = '';  //销售折扣
							item.salesPrice = '';  //销售单价
							item.profitC = '';  //实际利润
						})
						this.data = data.rows;
					}
				})
			} else {
				ElMessage({
					type: 'warning',
					message: '请输入查询条件',
				})
			}
			//阻止浏览器默认事件
			let e = window.event || arguments[0];
			if (e.key == 'Enter' || e.code == 'Enter' || e.keyCode == 13) {
				e.returnValue = false;
				return false;
			}
		},
		deleteRow(obj) { //删除商品的事件
			this.data.splice(obj.$index, 1);
		},
		close() { //关闭弹框
			this.$emit('update:modelValue', false);
		},
		confirm() { //确认的点击事件
			var data = JSON.parse(JSON.stringify(this.data.filter(v => !v.disabled)));
			data = data.filter(v => v.active);
			if (data.length) {
				data.map(item => {
					delete item.disabled;
				})
				this.$emit('success', data);
				//清空选项
				this.$refs.table.$refs.lidaTable.clearSelection();
				this.close();
			} else {
				ElMessage({
					type: 'warning',
					message: '请选择要添加的商品',
				})
			}
		},
		inputLv(row, type, linkage) { //监听面价点数和利润点数输入的事件
			row.numType = 'qty';
			row = offerProfitCompute(row, type, linkage);
		},
		clearData() {
			this.value = '';
			this.data = [];
		},
		selectGoods(data) {
			this.data.map(item => {
				item.active = data.some(v => v.id === item.id);
			})
		},
		checkSelectable(row) {
			return !row.disabled;
		},
	},
}
</script>

<style scoped lang="less">
.a {
	padding: 20px;

	.a-title {
		padding-bottom: 20px;
		font-weight: bold;
	}

	.a-footer {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 20px;
	}
}

.p {
	.p-top {
		display: flex;
		align-items: center;
		padding-bottom: 20px;

		span {
			white-space: nowrap;
			margin-right: 10px;
		}
	}
}

.t-row {
	display: flex;
	align-items: center;

	span {
		margin-left: 5px;
	}
}

.t-profit {
	display: flex;
	flex-direction: column;
	font-size: 14px;

	.t-profit-i {
		display: flex;
		padding-bottom: 5px;

		span {
			width: 100px;
			color: #999999;
		}
	}
}
</style>
